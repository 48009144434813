import authenticated from '@/middleware/authenticated';
import routeMap from '@/utils/routeMap';

const userRoutes = [
  {
    path: `/${routeMap.user.profile.path}`,
    component: () => import ('@/layout/UserProfile'),
    beforeEnter: authenticated,
    children: [
      {
        path: '/',
        name: routeMap.user.profile.name,
        component: () => import('@/views/User/ProfileInformation'),
      },
      {
        path: routeMap.user.address.path,
        name: routeMap.user.address.name,
        component: () => import('@/views/User/Address'),
      },
      {
        path: routeMap.user.order.path,
        name: routeMap.user.order.name,
        component: () => import('@/views/User/Orders'),
      },
      {
        path: routeMap.user.support.path,
        name: routeMap.user.support.name,
        component: () => import('@/views/User/SupportTicket'),
      },
      {
        path: routeMap.user.supportTicketDetails.path,
        name: routeMap.user.supportTicketDetails.name,
        component: () => import('@/views/User/SupportTicketDetails'),
      },
      {
        path: routeMap.user.orderReturn.path,
        name: routeMap.user.orderReturn.name,
        component: () => import('@/views/User/ReturnHistory'),
      },
      {
        path: routeMap.user.track.path,
        name: routeMap.user.track.name,
        component: () => import('@/views/User/ProfileInformation'),
      },
      {
        path: routeMap.user.shops.path,
        name: routeMap.user.shops.name,
        component: () => import('@/views/User/ShopRequest'),
      },

      {
        path: routeMap.user.createShop.path,
        name: routeMap.user.createShop.name,
        component: () => import('@/views/Pages/CreateShop'),
      },
    ],

  },
  {
    path: routeMap.user.orderSuccess.path,
    name: routeMap.user.orderSuccess.name,
    component: () => import('@/views/Cart/OrderSuccess'),
    beforeEnter: authenticated
  },
  {
    path: routeMap.user.cart.path,
    name: routeMap.user.cart.name,
    component: () => import('@/views/Cart/Cart'),
    beforeEnter: authenticated
  },
  {
    path: routeMap.user.invoice.path,
    name: routeMap.user.invoice.name,
    component: () => import('@/views/User/Invoice'),
    beforeEnter: authenticated,
  }
];


export default userRoutes;
