import state from '@/store/modules/common/state';
import getters from '@/store/modules/common/getters';
import mutations from '@/store/modules/common/mutations';
import actions from '@/store/modules/common/actions';
import { fetchBlogCategories, fetchBlogPosts } from '@/api/blog';
import { devLog } from '@/utils/helper';

export default {
  namespaced: true,

  state: () => ({
    ...state,
    data: {
      posts: [],
      pageCount: 0,
      latestPost: false, // {}
      categories: [],
    }
  }),

  getters: {
    ...getters
  },

  mutations: {
    ...mutations
  },

  actions: {
    ...actions,

    async getBlogCategories({ commit, dispatch, state }) {

      dispatch('handleAsyncAction', {
        async handler() {
          const response = await fetchBlogCategories();

          const categories = response.data.data;

          const formattedData = {
            ...state.data,
            categories
          }

          commit('updateData', formattedData);
        }
      });
    },



    async getBlogPosts({ commit, dispatch, state }, { categoryId, pageIndex }) {

      if (state.loading) {
        devLog(() => console.warn(`Request while previous one not finished on 'getBlogPosts'`));
      }

      dispatch('handleAsyncAction', {
        async handler() {
          if (!state.data.categories.length) {
            dispatch('getBlogCategories');
          }

          const params = new URLSearchParams();
          params.append('category', categoryId);
          params.append('page', pageIndex);

          const response = await fetchBlogPosts(params);

          const responseData = response.data.data;

          const formattedData = {
            ...state.data,
            posts: responseData.posts.data,
            pageCount: responseData.posts.last_page,
            latestPost: responseData.latestPost,
          }

          commit('updateData', formattedData);
        }
      });
    }
  }
};
