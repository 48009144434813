<template>
  <div
    class="search"
    :style="{width : `${(windowWidth - 10)}px`}"
  >
    <v-autocomplete
      clearable hide-details flat
      return-object solo outlined full-width
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      prepend-inner-icon="mdi-magnify"
      item-text="name"
      item-value="name"
      label="Search for product..."
      style="min-width: 100%"
      :no-data-text="noItemText"
      :allow-overflow="false"
      ref="searchInput"
      @click:clear="() => noResults = false"
      @keyup.enter="handleEnterClick"
    >
      <template v-slot:item="{ item }">
        <div class="d-flex" @click="selectProduct(item)">
          <v-list-item-avatar>
            <v-img :src="item.thumbnail"/>
          </v-list-item-avatar>
          <v-list-item-content>
            {{ item.name }}
          </v-list-item-content>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs, watch, ref } from '@vue/composition-api';
import { getProductBySearch } from '@/api/api';
import { debounce } from '@/utils/helper';
import { onStartTyping, useWindowSize } from '@vueuse/core'
export default defineComponent({
  name: 'ProductSearch',
  props: {
    small: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { root }) {

    const { width } = useWindowSize();

    const state = reactive({

      search: '',
      isLoading: false,
      items: [],
      model: null,
      noResults: false,
      windowWidth: width,

      noItemText: computed(()=> {

        if(!state.search){
          return 'Type to search'
        }

        if(state.search && state.search.length < 3){
          return 'Type to search'
        }

        return state.noResults
          ? 'No product found'
          : 'Type to search'
      })
    });


    const searchInput = ref(null)

    onStartTyping(()=> {
      if (!searchInput.value.active){
        searchInput.value.focus()
      }
    });

    const handleEnterClick = () => {

      const urlToGo = {
        name: 'Products',
        query: {
          search: encodeURI(state.search)
        }
      }

      return root.$router
        .push(urlToGo).then(()=> {
          // state.search = '';
        })
        .catch(()=>{})
      ;
    }

    const fetchResults = async () => {

      if(!state.search) {
        state.items = [];
        return
      }

      if(state.search.length < 3){
        state.items = [];
        return;
      }

      state.isLoading = true
      const response = await getProductBySearch({ search: state.search })
      state.items = response.data.data
      state.noResults = !state.items.length;
      state.isLoading = false

    };

    const selectProduct = (item) => {

      const urlToGo = {
      name: 'ProductSingle',
        params: {
          product: item.slug,
          vendor:item.shop.slug
        }
      }

      return root.$router
        .push(urlToGo)
        .catch(()=>{})
      ;
    }

    watch(()=> state.search, ()=> {
      debounce(fetchResults())
    });

    return {
      ...toRefs(state),
      selectProduct,
      searchInput,
      handleEnterClick
    };
  },

});
</script>

<style scoped lang="scss">
.w-100 {
  width: 100%;
}
.v-list.v-select-list.v-sheet.theme--light.v-list--dense.theme--light {
  // max-width: 280px;
}
.search{
  max-width: 100%;
  //width: 1200px;
  // width: 100% !important;
  //min-width: 280px;
  //width: 100%;
 // max-width: 280px;
}
</style>
