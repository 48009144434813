import {
  PRODUCT_SORT_BEST_SELLING,
  PRODUCT_SORT_NEW,
  PRODUCT_SORT_OLD,
  PRODUCT_SORT_PRICE_HIGH,
  PRODUCT_SORT_PRICE_LOW,
} from '@/store/modules/products/productTypes';

import { isEmpty } from 'lodash';
import state from '@/store/modules/common/state';
import getters from '@/store/modules/common/getters';
import mutations from '@/store/modules/common/mutations';
import actions from '@/store/modules/common/actions';
import { fetchVendors, products } from '@/api/api';
import { singleProduct } from '@/api/products';


export default {
  namespaced: true,

  state: () => ({
    ...state,
    basicSortOption: [

      {
        name: 'Price Low To High',
        value: PRODUCT_SORT_PRICE_LOW
      },
      {
        name: 'Price High To Low',
        value: PRODUCT_SORT_PRICE_HIGH
      },
      {
        name: 'Oldest',
        value: PRODUCT_SORT_OLD
      },
      {
        name: 'Newest',
        value: PRODUCT_SORT_NEW
      },
      {
        name: 'Best Selling',
        value: PRODUCT_SORT_BEST_SELLING
      }

      // todo: will be enabled after product view functionality ready
      // {
      //   name: 'Most Viewed',
      //   key: PRODUCT_SORT_POPULAR
      // }

    ],

    priceRange: {

      isUpdated: false,
      min: 0,
      max: 1

    },

    paginationLength: 0,

    filters: {

      basic: {
        sort_by: 'date', // [date, price, best selling],
        sort_high: 1, // [0, 1]
      },

      category: [],
      brand: null,
      shop: null,

      searchString: '',
      page: 1,

      priceRange: {
        min: 0,
        max: 0
      },

    },

    product: {},

    vendors: [],

  }),

  getters: {
    ...getters,
    allProducts: (state) => state?.data?.data,
    priceRange: (state) => state?.priceRange,
    basicSortOption: (state) => state?.basicSortOption,
    paginationLength: (state) => state?.paginationLength,
    product: (state) => state.product,
    vendors: (state) => state.vendors,
    hasVendor: (state)=> !isEmpty(state.vendors)
  },

  mutations: {
    ...mutations,
    updateFilters: (state, payload) => state.filters = payload,
    updatePriceRange: (state, payload) => state.priceRange = payload,
    updatePaginationLength: (state, payload) => state.paginationLength = payload,
    updateProduct: (state, payload) => state.product = payload,
    updateVendors: (state, payload) => state.vendors = payload
  },

  actions: {
    ...actions,

    async getProducts({ state, dispatch }) {

      const appliedFilters = { ...state.filters };

      dispatch('setLoading', true);

      const parameter = new URLSearchParams();

      for(let filterKey in appliedFilters) {

        const filterValue = appliedFilters[filterKey];

        switch(filterKey) {

          case 'basic':
            if(!filterValue) break;
            parameter.append('sort_by', filterValue.sort_by);
            parameter.append('sort_high', filterValue.sort_high);
            break;

          case 'category':
            if(!filterValue) break;
            filterValue.map((item,index) => {
              parameter.append(`category[${index}]`, item);
            })
            break;

          case 'brand':
            if(!filterValue) break;
            parameter.append(filterKey, filterValue);
            break;

          case 'shop':
            if(!filterValue) break;
            parameter.append(filterKey, filterValue);
            break;

          case 'searchString':
            if(!filterValue) break;
            parameter.append('search', filterValue);
            break;

          case 'priceRange':

            if(filterValue.min === state.priceRange.min && filterValue.max === state.priceRange.max) {
              break;
            }

            parameter.append('min_price', filterValue?.min);
            parameter.append('max_price', filterValue?.max);

            break;

          case 'page':
            if(!filterValue || filterValue <= 1) break;
            parameter.append('page', filterValue);
            break;

        }
      }

      let response = await products(parameter);

      dispatch('setData', response?.data?.data?.products || []);
      dispatch('setPaginationLength', response?.data?.data?.products?.last_page || 1);

      if(!state.priceRange.isUpdated) {

        const priceRange = {
          min: response?.data?.data?.price_range?.min,
          max: response?.data?.data?.price_range?.max,
          isUpdated: true
        };

        dispatch('setPriceRange', priceRange);
        dispatch('applyFilter', { type: 'priceRange', data: priceRange })
      }

      dispatch('setLoading', false);

    },

    applyFilter({ commit, state }, { type, data }) {


      const filters = { ...state.filters };

      const value = (isEmpty(data) && !data) ? null : data;

      for(let filterKey in filters) {

        if(filterKey === type) {

          filters[filterKey] = value;

        }

      }

      commit('updateFilters', filters);
    },

    setPriceRange({ commit }, payload) {
      commit('updatePriceRange', payload);
    },

    setPaginationLength({ commit }, payload) {
      commit('updatePaginationLength', payload);
    },

    async getProduct({ commit, dispatch }, { productSlug, vendorSLug }) {

      dispatch('setLoading', true);

      const response = await singleProduct(productSlug, vendorSLug);
      commit('updateProduct', response.data.data);

      dispatch('setLoading', false);
    },

    async fetchVendors({ commit, dispatch }){
      dispatch('setLoading', true);
      const response = await fetchVendors();
      commit('updateVendors', response.data.data);
      dispatch('setLoading', false);
    }
  },
};

