<template>
  <div class="footer">
    <!--top section-->
    <v-card class="pt-10 pb-10" flat tile>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" lg="4" order-sm="1" order-lg="1">
            <v-img :src="this.$apps.logo" width="100%"></v-img>
            <v-card-text>
              A marketplace both for physical and digital goods.
            </v-card-text>
          </v-col>
          <v-col cols="12" sm="6" lg="4" order-sm="2" order-lg="2">
            <v-card-text class="text-uppercase title display-4 font-weight-bold pb-2 pt-2 pl-0">Useful Link
            </v-card-text>
            <v-divider class="pb-2"></v-divider>
            <div class="link-container">
              <v-card-text
                v-for="(navigation, index) in footerUsefulLink"
                :key="'foot-nav ' + index"
                class="pb-1 pt-1 pl-0">
                <router-link :to="{name : navigation.routeName}">
                  <v-icon class="mr-1">mdi-chevron-right</v-icon>
                  {{ navigation.name }}
                </router-link>
              </v-card-text>
            </div>
          </v-col>
          <v-col cols="12" sm="6" lg="4" order-sm="2" order-lg="3">
            <v-card-text class="text-uppercase title display-4 font-weight-bold pb-2 pt-2 pl-0">Contact Us</v-card-text>
            <v-divider class="pb-2"></v-divider>
            <v-card-text class="pt-1 pb-1 pl-0">
              <v-icon class="mr-2">mdi-home</v-icon>
              {{ $apps.address }}
            </v-card-text>
            <v-card-text class="pt-1 pb-1 pl-0">
              <v-icon class="mr-2">mdi-phone</v-icon>
              <a
                :href="`tel:${$apps.phone}`"
              >
                {{ $apps.phone }}
              </a>
            </v-card-text>
            <v-card-text class="pt-1 pb-1 pl-0">
              <v-icon class="mr-2">mdi-earth</v-icon>
              {{ $apps.web }}
            </v-card-text>
            <v-card-text class="pt-1 pb-1 pl-0">
              <v-icon class="mr-2">mdi-email</v-icon>
              <a
                :href="`mailto:${$apps.email}`"
              >
              {{ $apps.email }}
              </a>
            </v-card-text>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-divider></v-divider>

    <!--bottom section-->
    <v-card class="text-center dark" flat tile>
      <v-container>
        <v-card-text>
          <v-btn v-for="icon in socialLinks" :key="icon.name" class="mx-4" icon>
            <v-icon :color="icon.name.toLowerCase()" size="24px">
              {{ icon.icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-row>
          <v-card-text class="grey--text text--darken-1">
            {{ new Date().getFullYear() }} All Rights Reserved @
            <router-link :to="{name : 'Home'}"><strong>{{ $apps.name }}.</strong></router-link>
            Powered by
            <a
              class="grey--text text--darken-1 font-weight-bold"
              href="https://snebtaf.co.uk"
              target="_blank"
            >
              Snebtaf
            </a>
          </v-card-text>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import footerUsefulLink from '@/utils/footerUsefulLink';
import routeMap from '@/utils/routeMap';
import { defineComponent, reactive, toRefs } from '@vue/composition-api'
export default defineComponent({
  name: 'app-footer',
  setup(){
    const state = reactive({
      socialLinks: [
        {
          icon: 'mdi-facebook',
          name: 'facebook',
          link: '#'
        }, {
          icon: 'mdi-twitter',
          name: 'twitter',
          link: '#',

        }, {
          icon: 'mdi-linkedin',
          name: 'linkedin',
          link: '#'
        }, {
          icon: 'mdi-instagram',
          name: 'instagram',
          link: '#'
        }
      ],
      footerUsefulLink: footerUsefulLink,
    });

    return {
      ...toRefs(state),
      routeMap
    }
  },
});
</script>

<style>
.footer {
  border-top: 1px solid #74c9f3;
}
</style>
