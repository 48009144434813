<template>
    <div class="pa-0 ma-0">
      <v-app-bar :app="true" color="white" clipped-right elevation="0" class="top-nav">
        <v-container class="py-0 fill-height">

          <!--logo-->
          <router-link :to="{name : 'Home'}">
            <v-avatar height="35" tile width="auto">
              <img :alt="$apps.name" :src="require('@/assets/images/logo.png')">
            </v-avatar>
          </router-link>

          <v-spacer></v-spacer>

          <div class="d-none d-lg-flex">

            <v-btn
              v-for="(link, index) in links"
              :key="'link' + index"
              :to="{name: link.routeName}"
              active-class="secondary"
              class="mr-2"
              exact
              text
            >
              {{ link.title }}
            </v-btn>

            <div v-if="isAuthenticated">
              <v-btn
                v-for="(link, index) in authenticatedRoutes"
                :key="'link' + index"
                :to="{name: link.routeName}"
                class="mr-2"
                exact
                text
              >
                {{ link.title }}
              </v-btn>
              <v-btn class="mr-2" depressed @click="logout"> Logout</v-btn>
            </div>
            <div v-else>

              <v-menu
                offset-y
                transition="slide-y-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="secondary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                    depressed
                    outlined
                  >
                    Login
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    :to="{name: 'Login'}"
                    exact
                  >
                    Customer Login
                  </v-list-item>

                  <v-list-item
                    exact
                    :href="adminRoute"
                    target="_blank"
                  >
                    Merchant Login
                  </v-list-item>
                </v-list>
<!--                <v-btn-->
<!--                  v-for="(link, index) in authenticationRoute"-->
<!--                  :key="'link' + index"-->
<!--                  :to="{name: link.routeName}"-->
<!--                  class="mr-2"-->
<!--                  exact-->
<!--                  text-->
<!--                >-->
<!--                  {{ link.title }}-->
<!--                </v-btn>-->
<!--                <v-list>-->
<!--                  <v-list-item-->
<!--                    v-for="(item, index) in items"-->
<!--                    :key="index"-->
<!--                  >-->
<!--                    <v-list-item-title>{{ item.title }}</v-list-item-title>-->
<!--                  </v-list-item>-->
<!--                </v-list>-->
              </v-menu>


<!--              <v-btn-->
<!--                v-for="(link, index) in authenticationRoute"-->
<!--                :key="'link' + index"-->
<!--                :to="{name: link.routeName}"-->
<!--                class="mr-2"-->
<!--                exact-->
<!--                text-->
<!--              >-->
<!--                {{ link.title }}-->
<!--              </v-btn>-->
            </div>
          </div>

<!--          <ProductSearch class="d-none d-sm-flex"/>-->

          <CartButton
            :cart-item-count="cartContent"
            :route-to="{name : 'Cart'}"
          />

          <v-app-bar-nav-icon
            class="hidden-lg-and-up"
            @click="navigationDrawer"
          />

        </v-container>
      </v-app-bar>

      <div class="pt-16 ma-0">
        <v-divider/>
        <v-toolbar elevation="0" color="white">
          <v-container fluid>
            <v-row align="center" justify="center">
              <div class="d-flex">

                <div class="container">
                  <ProductSearch />
                </div>

<!--                <v-btn-->
<!--                  v-for="(link, index) in links"-->
<!--                  :key="'link' + index"-->
<!--                  :to="{name: link.routeName}"-->
<!--                  active-class="secondary"-->
<!--                  class="mr-2"-->
<!--                  exact-->
<!--                  text-->
<!--                >-->
<!--                  {{ link.title }}-->
<!--                </v-btn>-->

<!--                <div v-if="isAuthenticated">-->
<!--                  <v-btn-->
<!--                    v-for="(link, index) in authenticatedRoutes"-->
<!--                    :key="'link' + index"-->
<!--                    :to="{name: link.routeName}"-->
<!--                    class="mr-2"-->
<!--                    exact-->
<!--                    text-->
<!--                  >-->
<!--                    {{ link.title }}-->
<!--                  </v-btn>-->
<!--                  <v-btn class="mr-2" depressed @click="logout"> Logout</v-btn>-->
<!--                </div>-->
<!--                <div v-else>-->
<!--                  <v-btn-->
<!--                    v-for="(link, index) in authenticationRoute"-->
<!--                    :key="'link' + index"-->
<!--                    :to="{name: link.routeName}"-->
<!--                    class="mr-2"-->
<!--                    exact-->
<!--                    text-->
<!--                  >-->
<!--                    {{ link.title }}-->
<!--                  </v-btn>-->
<!--                </div>-->
              </div>
            </v-row>
          </v-container>
        </v-toolbar>
      </div>
    </div>
</template>

<script>
import headerNavigation from '@/utils/headerNavigation';
import {ADMIN_BASE_URL, storeHelper} from '@/utils/helper';
import { CART_COUNT, cartNameSpace } from '@/store/modules/cart/types';
import CartButton from '@/components/Utils/CartButton';
import ProductSearch from '@/components/Utils/ProductSearch';

export default {
  name: 'app-header',
  components: { ProductSearch, CartButton },
  data: () => ({
    adminRoute: ADMIN_BASE_URL,
    links: headerNavigation,

    authenticatedRoutes: [
      {
        title: 'Profile',
        routeName: 'UserProfile',
        link: '',
        icon: ''
      },
    ],
    authenticationRoute: [
      {
        title: 'Login',
        routeName: 'Login',
        link: '',
        icon: ''
      },
      {
        title: 'Admin Login',
        routeName: 'Login',
        link: '',
        icon: ''
      },
    ],
  }),
  computed: {
    cartContent() {
      return this.$store.getters[storeHelper(cartNameSpace, CART_COUNT)];
    },
    isAuthenticated() {
      return this.$store.getters['app/isLoggedIn'];
    }
  },
  methods: {

    navigationDrawer() {
      this.$store.dispatch('app/setNavigationDrawer', !this.$store.getters['app/navigationDrawer']);
    },

    async logout() {

      await this.$store.dispatch('app/tryLogout');

      if(this.$route.name !== 'Home') {
        await this.$router.push({ name: 'Home' });
      }
    }
  }
};
</script>

<style lang="scss">
.top-nav {
  .v-toolbar__content {
    border-bottom: 1px solid #74c9f3;
  }
}
</style>
