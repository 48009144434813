import { http } from '@/plugins/http';

import {
  ALL_ORDER_URL,
  CANCEL_SHOP_URL, GET_CONVERSATION, GET_SUPPORT_TICKET,
  GET_USER_RETURNED_ORDER_LIST,
  GET_USER_SHOPS_URL, RESOLVE_TICKET, SEND_MESSAGE, SUBMIT_ORDER_ISSUE,
  USER_ADDRESS_CREATE_URL,
  USER_ADDRESS_DELETE_URL, USER_ADDRESS_MAKE_DEFAULT,
  USER_ADDRESS_UPDATE_URL,
  USER_PROFILE_UPDATE_URL
} from '@/utils/api';

import { getApiResponse } from '@/utils/helper';

export const updateProfile = async (data) => {
  return getApiResponse(() => http.post(USER_PROFILE_UPDATE_URL, data));
};

export const userAddressCreate = async (data) => {
  return getApiResponse(() => http.post(USER_ADDRESS_CREATE_URL, data));
};

export const userAddressDelete = async (id) => {
  return getApiResponse(() => http.delete(`${USER_ADDRESS_DELETE_URL}/${id}`));
};

export const userAddressUpdate = async (data, id) => {
  return getApiResponse(() => http.post(`${USER_ADDRESS_UPDATE_URL}/${id}`, data));
};

export const toggleDefaultAddress = (id) => getApiResponse(() => http.patch(`${USER_ADDRESS_MAKE_DEFAULT}/${id}`));

export const userOrders = async () => {
  return getApiResponse(() => http.get(ALL_ORDER_URL));
};

export const userShops = async () => {
  return getApiResponse(() => http.get(GET_USER_SHOPS_URL));
};

export const cancelShop = async (id) => {
  return getApiResponse(() => http.post(CANCEL_SHOP_URL, id));
};

export const getReturnedOrder = () => getApiResponse(() => http.get(GET_USER_RETURNED_ORDER_LIST));

export const getUserTickets = ()=> getApiResponse(()=> http.get(GET_SUPPORT_TICKET));

export const getMessages = (id)=> getApiResponse(()=> http.get(`${GET_CONVERSATION}/${id}`))

export const sendSupportMessage = (id, message)=> {
  const url = SEND_MESSAGE.replace(':id', id);
  return getApiResponse(()=> http.post(url, message))
}

export const resolveTicket = (id, data)=> getApiResponse(()=> http.patch(`${RESOLVE_TICKET}/${id}`, data));

export const submitOrderIssue = (data) => getApiResponse(()=> http.post(SUBMIT_ORDER_ISSUE, data));

