import Vue from 'vue';
import App from './App.vue';
import './plugins/';
import './utils/appConstants';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import { appMixin } from '@/mixins/app';

import { googleAnalyticsKey } from '@/config/app'

Vue.mixin(appMixin);

Vue.config.productionTip = false;

import VueAnalytics from 'vue-analytics';

Vue.use(VueAnalytics, {
  id: googleAnalyticsKey,
  router
});


new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
