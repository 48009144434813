<template>
<div class="sh-cart-badges">
  <v-badge
    :color="cartItemCount?
    'primary' : 'transparent'"
    :content="cartItemCount"
    class="ml-2 text--white"
    overlap
  >
    <v-btn
      :disabled="!cartItemCount"
      :to="routeTo" depressed
      :color="cartItemCount ? 'secondary':''"
    >
      <v-icon> mdi-cart</v-icon>
    </v-btn>
  </v-badge>
</div>
</template>

<script>
export default {
  name: 'CartButton',
  props: {

    cartItemCount: {
      type: Number,
      default: () => 0,
    },

    routeTo: {
      type: Object,
      default: () => {}
    }
  },
};
</script>

<style lang="scss" scoped>

.sh-cart-badges::v-deep .v-badge {
  .v-badge__wrapper{
    .v-badge__badge.primary {
      color: white !important;
    }
  }
}

</style>
