import { reactive, computed, toRefs } from '@vue/composition-api'

import store from '@/store/index'

export default function(){


  const state = reactive({

    isLoggedIn: computed(()=> store.getters['app/isLoggedIn']),
    isBootstrapped: computed(()=> store.getters['app/isBooted']),
    isGuest: computed(()=> store.getters['app/isGuest']),

  })

  const bootstrapApp = async ()=> await store.dispatch('app/validateToken');

  const validateUserIsAuthenticated = async () => {

    if(state.isBootstrapped){
      return state.isLoggedIn
    }

    await bootstrapApp();

    return state.isLoggedIn;
  };
  const validateUserIsGuest = async () => {
    await validateUserIsAuthenticated();

    return state.isGuest;
  };

  return {
    ...toRefs(state),
    validateUserIsAuthenticated,
    validateUserIsGuest,
    bootstrapApp
  }
}
