import _ from 'lodash';
import { apiFailedResponse } from '@/api/apiResponse';
import dayjs from 'dayjs';
import Store from '@/store/index';
import collect from 'collect.js';
import { computed, reactive, toRefs } from '@vue/composition-api';

export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
export const ADMIN_BASE_URL = process.env.VUE_APP_ADMIN_BASE_URL;

let utc = require('dayjs/plugin/utc');
let timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
let advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

export const storeHelper = (namespace, state) => (`${ namespace }/${ state }`);

export const avatarText = (name) => {
  name = name.toString(); // To avoid error
  if (name.split(' ').length > 1) {
    let data = name.split(' ');
    return data[0] + data[1];
  }

  return name[0];
};

export const debounce = (fn, delay = 50000) => {
  return _.debounce(() => {
    fn();
  }, delay);
};

export const throttle = (fn, delay = 500) => {
  return _.throttle(async (fn) => {
    await fn();
  }, delay);
};

export const getApiResponse = async (callback) => {
  try {
    return await callback();
  } catch (e) {
    return apiFailedResponse(e);
  }
};

export const settingHelper = (key) => {

  let settings = Store.getters['app/settings'];
  let collection = collect(settings);

  if (collection.isEmpty()) {
    return;
  }

  let result = collection.where('key', key).first()?.value;

  return result;
};


export const formatDate = () => {
  let formatted = dayjs('2021-09-27T12:14:05.000000Z')
    .tz('Europe/London')
    .format('Do MMMM @ hh:mm A');
  return formatted;
};

export const apiUrlHelper = (path, url) => `${ API_BASE_URL }/storage/${ path }/${ url }`;

export const devLog = (cb) => {
  if (process.env.NODE_ENV !== 'development') return;

  if (typeof cb !== 'function') throw new Error('Arguments must be callable');

  cb();
};


export const isNumber = (value) => {
  if (!value) return;
  return typeof parseInt(value) === 'number';
};

export const isEmail = (value) => {

  if (!value) return;

  let rules = /\S+@\S+\.\S+/;
  return rules.test(value);
};

export const sameAs = (firstValue, secondValue) => {

  if (!firstValue || !secondValue) {
    devLog(() => {
      console.warn('All field is required');
    });
    return false;
  }

  return firstValue === secondValue;

};

export const isValidUkPostCode = (postCode) => {

  if (!postCode) return;

  const regex = /^[A-Z]{1,2}[0-9RCHNQ][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$|^[A-Z]{2}-?[0-9]{4}$/;
  return regex.test(postCode.toString().toUpperCase());
};

export const validName = (name) => {
  if (!name) return;

  const regex = /^[A-Za-z\s-.]+$/;
  return regex.test(name);
};

export const addToRecentViewList = (product) => {

  const previousData = getFromLocalStorage('recently-open', true);

  let dataToStore = [product];

  if (previousData.length) {

    dataToStore = [product, ...previousData];

    const collection = collect(dataToStore)
      .unique('id')
      .slice(0, 5);

    dataToStore = collection;
  }


  const items = dataToStore;

  setToLocalStorage('recently-open', items, true);
};

export const setToLocalStorage = (key, value, stringify = false) => {

  const data = stringify
    ? JSON.stringify(value)
    : value;

  localStorage.setItem(key, data);
};

export const getFromLocalStorage = (key, parsed = false, fallback = []) => {
  const data = parsed
    ? JSON.parse(localStorage.getItem(key))
    : localStorage.getItem(key)
  ;

  if (_.isEmpty(data)) {
    return fallback;
  }

  return data;
};

export const removeFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const recentlyViewedProducts = () => getFromLocalStorage('recently-open', true) || [];


/**
 * Detects the actual image (mime) type
 * @param file : Blob|ArrayBuffer
 * @param fallback : String
 * @return {string|null}
 */
export function getImageRealMimeType(file, fallback = null) {

  const byteArray = (new Uint8Array(file)).subarray(0, 4);

  let header = '';
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }

  switch (header) {
    case '89504e47':
      return 'image/png';
    case '47494638':
      return 'image/gif';
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return 'image/jpeg';
    default:
      return fallback;
  }
}
