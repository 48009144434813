export default [
  {
    name: 'Privacy Policy',
    routeName: 'PrivacyPolicy',
    link: ''
  },
  {
    name: 'Terms and Condition',
    routeName: 'TermsAndCondition',
    link: ''
  },
  {
    name: 'Refund Policy',
    routeName: '',
    link: ''
  },
  {
    name: 'Return Policy',
    routeName: '',
    link: ''
  },
  {
    name: 'Contact US',
    routeName: 'Contact',
    link: ''
  },
];
