import apps from './appConstants';
import dayjs from 'dayjs';
import appConfig from '@/config/app';
import {
  PRODUCT_SORT_BEST_SELLING,
  PRODUCT_SORT_NEW,
  PRODUCT_SORT_OLD,
  PRODUCT_SORT_POPULAR,
  PRODUCT_SORT_PRICE_HIGH,
  PRODUCT_SORT_PRICE_LOW
} from '@/store/modules/products/productTypes';

export const moneyFormatter = (value) => {

  let money = value / 100;

  let formatter = new Intl.NumberFormat(apps.locale, {
    style: 'currency',
    currency: apps.currency,
  });

  let finalValue = money;
  return formatter.format(parseFloat(finalValue));
};

export const dateFormatter = (date) => {
  return dayjs(date).format(appConfig.dateFormat);
};


export const sortOption = [

  {
    name: 'Price Low To High',
    value: PRODUCT_SORT_PRICE_LOW
  },
  {
    name: 'Price High To Low',
    value: PRODUCT_SORT_PRICE_HIGH
  },
  {
    name: 'Oldest',
    value: PRODUCT_SORT_OLD
  },
  {
    name: 'Newest',
    value: PRODUCT_SORT_NEW
  },
  {
    name: 'Most Viewed',
    key: PRODUCT_SORT_POPULAR
  },
  {
    name: 'Best Selling',
    key: PRODUCT_SORT_BEST_SELLING
  }

];

export const IMAGES = {
  PAYMENT_METHOD: {
    CARD: require('@/assets/images/paymentMethods/card-2.png'),
    CASH: require('@/assets/images/paymentMethods/cash.jpg'),
  }
};
