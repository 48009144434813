import appStore from '@/store/modules/app/index';
import brandStore from '@/store/modules/brand/index';
import cartStore from '@/store/modules/cart/index';
import { cartNameSpace } from '@/store/modules/cart/types';
import category from '@/store/modules/category/category';
import homePage from '@/store/modules/homePage/homePage';
import pages from '@/store/modules/pages';
import productStore from '@/store/modules/products/index';
import userStore from '@/store/modules/user/index';
import vendorStore from '@/store/modules/vendor/index';
import blogStore from '@/store/modules/blog';



let module = {
  brand: brandStore,
  product: productStore,
  vendor: vendorStore,
  app: appStore,
  user: userStore,
  [cartNameSpace]: cartStore,
  home: homePage,
  category: category,
  pages: pages,
  blog: blogStore
}

export default module;

// :Todo will Implement Later to import all Modules Automatically

// /**
//  * Automatically imports all the modules and exports as a single module object
//  */
// const requireModule = require.context('.', true, /\.store\.js$/)
// const modules = {}
//
// requireModule.keys().forEach((path) => {
//   const fileName = path.replace(/^.*[\\/]/, '')
//   // create the module name from file name
//   // - remove the store.js
//   // - kebab-case to camelCase
//   const moduleName = fileName
//     .replace(/(\.\/|\.store\.js)/g, '')
//     .replace(/-./g, (x) => x.toUpperCase()[1])
//
//   modules[moduleName] = requireModule(path).default || requireModule(path)
// })
//
// export default modules


