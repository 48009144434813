import state from '@/store/modules/common/state';
import getters from '@/store/modules/common/getters';
import mutations from '@/store/modules/common/mutations';
import actions from '@/store/modules/common/actions';
import {getUserTickets} from '@/api/user';

export default {
  namespaced: true,

  state: () => ({
    ...state,
    data: {},
    userOrders: [],
    userShop: [],
    userTickets: [],
  }),

  getters: {
    ...getters,
    userOrders: (state) => state.userOrders,
    userShop: (state) => state.userShop,
    userTickets: (state) => state.userTickets,
  },

  mutations: {
    ...mutations,
    userOrders: (state, payload) => state.userOrders = payload,
    userShop: (state, payload) => state.userShop = payload,
    updateUserTicket: (state, payload) => state.userTickets = payload,
  },

  actions: {
    ...actions,
    userOrders: ({ commit }, payload) => commit('userOrders', payload),
    userShop: ({ commit }, payload) => commit('userShop', payload),

    // Get user support ticket
    getUserTicket({dispatch, commit}){
      dispatch('handleAsyncAction', {
        handler: async ()=>{
          const response = await getUserTickets();
          commit('updateUserTicket', response.data.data)
        }
      })
    },
  }
};
