import {
  CART_ADD,
  CART_CLEAR,
  CART_COUNT,
  CART_COUPON_APPLIED,
  CART_COUPON_DISCOUNT,
  CART_DATA,
  CART_DISCOUNT_AMOUNT,
  CART_DISCOUNT_TYPE,
  CART_ERROR_MSG,
  CART_ERRORS,
  CART_GRAND_TOTAL,
  CART_LOADING,
  CART_REMOVE,
  CART_SAVED_ORDER,
  CART_SHIPPING_CHARGE,
  CART_SUBTOTAL
} from '@/store/modules/cart/types';

export default {
  namespaced: true,

  state: () => ({
    [CART_LOADING]: false,
    [CART_DATA]: [],
    [CART_ERRORS]: [],
    [CART_ERROR_MSG]: '',
    [CART_SHIPPING_CHARGE]: 0,
    [CART_DISCOUNT_TYPE]: '',
    [CART_DISCOUNT_AMOUNT]: 0,
    [CART_COUPON_DISCOUNT]: 0,
    [CART_SAVED_ORDER]: {},
  }),

  getters: {
    [CART_LOADING]: (state) => state[CART_LOADING],
    [CART_DATA]: (state) => state[CART_DATA],
    [CART_ERRORS]: (state) => state[CART_ERRORS],
    [CART_ERROR_MSG]: (state) => state[CART_ERROR_MSG],
    [CART_SHIPPING_CHARGE]: (state) => state[CART_SHIPPING_CHARGE],
    [CART_COUPON_APPLIED]: (state) => !!state[CART_COUPON_DISCOUNT],
    [CART_DISCOUNT_AMOUNT]: (state) => state[CART_DISCOUNT_AMOUNT],
    [CART_SAVED_ORDER]: (state) => state[CART_SAVED_ORDER],

    [CART_COUNT]: (state) => {
      let item = state[CART_DATA];

      if (!item.length) {
        return 0;
      }
      return item
        .map((item) => item.quantity)
        .reduce((acc, val) => (acc + val), 0);
    },

    [CART_SUBTOTAL]: (state) => {
      let cartItem = state[CART_DATA];

      if (!cartItem.length) {
        return 0;
      }

      return cartItem
        .map((item) => item.quantity * item.price)
        .reduce((acc, val) => (acc + val), 0);
    },

    [CART_GRAND_TOTAL]: (state, getters) => {
      let itemTotal = getters[CART_SUBTOTAL];
      let minusAmount = state[CART_DISCOUNT_AMOUNT] + state[CART_COUPON_DISCOUNT];
      let finalAmount = itemTotal - minusAmount;
      let grandFinal = finalAmount + state[CART_SHIPPING_CHARGE];
      return grandFinal;
    }
  },

  mutations: {

    [CART_LOADING]: (state, payload) => state[CART_LOADING] = payload,
    [CART_DATA]: (state, payload) => state[CART_DATA] = payload,
    [CART_ERRORS]: (state, payload) => state[CART_ERRORS] = payload,
    [CART_ERROR_MSG]: (state, payload) => state[CART_ERROR_MSG] = payload,
    [CART_SAVED_ORDER]: (state, payload) => state[CART_SAVED_ORDER] = payload,

  },

  actions: {

    [CART_LOADING]: ({ commit }, payload) => commit(CART_LOADING, payload),
    [CART_DATA]: ({ commit }, payload) => commit(CART_DATA, payload),
    [CART_ERRORS]: ({ commit }, payload) => commit(CART_ERRORS, payload),
    [CART_ERROR_MSG]: ({ commit }, payload) => commit(CART_ERROR_MSG, payload),
    [CART_SAVED_ORDER]: ({ commit }, payload) => commit(CART_SAVED_ORDER, payload),

    [CART_ADD]: ({ dispatch, state }, payload) => {
      let cartData = [...state[CART_DATA]];

      let item = {
        ...payload,
        quantity: payload.quantity || 1
      };

      let itemExist = false;

      cartData.map((cartItem) => {

        if (cartItem.id === item.id) {
          itemExist = true;
          cartItem.quantity++;
        }
      });

      if (!itemExist) {
        cartData.push(item);
      }

      dispatch(CART_DATA, cartData);
    },

    [CART_REMOVE]: ({ dispatch, state }, { payload, type = 'remove', }) => {

      let cartData = [...state[CART_DATA]];

      if (type !== 'remove') {
        cartData.map((item) => {
          if (item.id === payload.id) {
            if (item.quantity > 1) {
              item.quantity--;
            }
          }
        });
      }

      if (type === 'remove') {
        cartData = cartData.filter((item) => {
          return item.id !== payload.id;
        });
      }

      dispatch(CART_DATA, cartData);
    },
    [CART_CLEAR]: async ({ dispatch }) => {

      // Remove All Item from this store
      await dispatch(CART_DATA, []);
      await dispatch(CART_ERRORS, []);
      await dispatch(CART_ERROR_MSG, '');
      await dispatch(CART_ERROR_MSG, '');
      await dispatch(CART_SAVED_ORDER, {});
    }
  }
};
